























































import { Component, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import FormField from '@/shared/components/form/FormField.vue'
import { DataTableHeader } from 'vuetify'
import ReportService, { GenerateType } from '@/shared/modules/report/ReportService'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import moment from 'moment'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import __ from '@/shared/helpers/__'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import toggleablePermissions from '@/shared/helpers/toggleable-permissions.helper'
import { RoleLevel } from '@/shared/modules/role/role.model'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'

@Component({
    components: { FormField },
    methods: { __ }
  })
  export default class PaidHours extends Vue {
    types = GenerateType
    RoleLevel = RoleLevel
    fromField: Field = new Field().setType(FieldTypes.datePicker).setKey('from').setTitle(__('company.views.reports.index.tabs.paid-hours.form.fields.from')).setDense(true)
    toField: Field = new Field().setType(FieldTypes.datePicker).setKey('to').setTitle(__('company.views.reports.index.tabs.paid-hours.form.fields.to')).setDense(true).setMeta({ minKey: () => 'from' })
    employeeField: Field | any = new SearchableField().setKey('employee_uuid').setTitle(__('company.views.reports.index.tabs.paid-hours.form.fields.employee'))
        .setDense(true)
        .loadItems({
          endpoint: `company/${ this.company.slug }/employees`,
          value: 'uuid',
          title: 'full_name',
          perPage: 20
        })
        .isRequired()
    projectField: Field = new SearchableField().setKey('project_uuid').setTitle(__('company.views.reports.index.tabs.paid-hours.form.fields.project')).setDense(true).loadItems({
      endpoint: `company/${ this.company.slug }/projects`,
      value: 'uuid',
      title: 'name',
      perPage: 20,
      params: {
        permission: toggleablePermissions.reports.notAssignedProjects
      }
    })
    data: any = null
    loading: GenerateType|null = null
    employeePaidHoursData: any = null
    employeeLoading: GenerateType|null = null

    form: FormBase = new FormBase()
      .setData({
        from: moment.utc().startOf('month').format('YYYY-MM-DD'),
        to: moment.utc().endOf('month').format('YYYY-MM-DD'),
        employee_uuid: this.isEmployee ? this.user.uuid : '',
        project_uuid: '',
      })

    generate(type: GenerateType): void {
      this.loading = type
      ReportService.paidHours(this.company, type, this.form.data.from, this.form.data.to)
        .then((response: any) => {
          this.form.setErrors({})
          if (type === GenerateType.pivot) {
            this.employeePaidHoursData = null
            this.data = response.data
            return
          }
          downloadFile(response, `paid-hours-report.${ type }`)
        })
        .catch((error: any) => this.form.catchErrors(error))
        .finally(() => this.loading = null)
    }

    generateByProjectAndEmployee(type: GenerateType) {
      this.employeeLoading = type
      const { from, to, employee_uuid, project_uuid } = this.form.data
      ReportService.employeePaidHoursByProject(this.company, type, from, to, employee_uuid, project_uuid)
        .then((response: any) => {
          this.form.setErrors({})
          if (type === GenerateType.pivot) {
            this.data = null
            this.employeePaidHoursData = response.data
            return
          }

          downloadFile(response, `employee-paid-hours-by-project-report.${ type }`)
        })
        .catch(async (error: any) => {
          if (type !== GenerateType.pivot) {
            const data = await error.response.data.text()
            error.response.data = JSON.parse(data)
          }

          this.form.catchErrors(error)
        })
        .finally(() => this.employeeLoading = null)
    }

    get isEmployee(): boolean {
      return this.user.role.level === RoleLevel.employee
    }

    get headers(): DataTableHeader[] {
      return [
        {
          value: 'user',
          text: __('company.views.reports.index.tabs.paid-hours.table.headers.user'),
          sortable: false,
        },
        ...this.data.projects ? Object.keys(this.data.projects).map((uuid: string) => ({
          value: uuid,
          text: this.data.projects[uuid].name,
          sortable: false,
        })) : [],
        {
          value: 'total',
          text: __('company.views.reports.index.tabs.paid-hours.table.headers.total'),
          sortable: false,
        },
      ]
    }

    get items(): any {
      const footer: any = {
        user: __('company.views.reports.index.tabs.paid-hours.table.footers.total'),
        total: this.data.total,
      }
      if (this.data.projects) {
        Object.keys(this.data.projects).forEach(
          (uuid: string) => footer[uuid] = this.data.projects[uuid].total
        )
      }
      return [
        ...this.data.users.map((user: any) => ({
          user: user.name,
          ...user.projects,
          total: user.total,
        })),
        footer
      ]
    }

    get employeeHeaders(): DataTableHeader[] {
      return [
        {
          value: 'date',
          text: __('company.views.reports.index.tabs.paid-hours.table.headers.date'),
          sortable: false,
          width: 160,
        },
        {
          value: 'description',
          text: __('company.views.reports.index.tabs.paid-hours.table.headers.projects'),
          sortable: false,
        },
        {
          value: 'taskTypes',
          text: __('company.views.reports.index.tabs.paid-hours.table.headers.task-types'),
          sortable: false,
        },
        {
          value: 'time',
          text: __('company.views.reports.index.tabs.paid-hours.table.headers.time'),
          sortable: false,
          width: 120,
        }
      ]
    }

    get employeeItems(): any {
      return [
        ...this.employeePaidHoursData.data.map((item: any) => ({
          ...item,
          description: item.projects.join(', '),
          taskTypes: item.taskTypes.join(', '),
        })),
        {
          date: '',
          description: '',
          time: this.employeePaidHoursData.total,
        }
      ]
    }

    get company(): Company {
      return this.$store.getters[CompanyGetters.getCompany]
    }

    get user(): User {
      return this.$store.getters[AuthGetters.getUser]
    }
  }
